import axios from "axios";

// let access_key = "gp95xgb8y9ibd2esl59jl0r7008l8u19ygkfbh8gym32mxqvq95h6nq2x72j";
let access_key = "ai0f1ni2394op690xr4rrup7rgjf6kf4kgsuogdix42c76ujogm30y4fzdbq";

let fred_key = "905efca86e16f4df3e1e5b6a36131f9d";

export default class CommoditiesService {
    data() {
        return {
            data: null,
        };
    }

    async getKina() {//used
        return await axios.get(`https://commodities-api.com/api/latest?access_key=${access_key}&base=PGK&symbols=USD,AUD,EUR,JPY`)
            .then(result => {
                // console.log(result.data.data.rates); // This will log the response data
                const kina = result.data.data.rates; // Get the most recent news post from the response
                return kina; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async extractstocks(stock) {//used
        return await axios.get(`https://api.stlouisfed.org/fred/series/observations?series_id=${stock}&api_key=${fred_key}&file_type=json&sort_order=desc&limit=1`)
            .then(result => {
                console.log(result.data); // This will log the response data
                // const mostRecentNews = result.data; // Get the most recent news post from the response
                // return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }

    async extractWorldNews(url) {//used
        return await axios.get(`https://api.worldnewsapi.com/extract-news?url=${url}&analyze=true&api-key=af5a3aad4f8c44deafe2933534afc882`)
            .then(result => {
                // console.log(result.data); // This will log the response data
                const mostRecentNews = result.data; // Get the most recent news post from the response
                return mostRecentNews; // Return the most recent news post to be used in the component
            })
            .catch(error => {
                console.error("Error fetching news:", error);
                throw error; // Rethrow the error so it can be handled in the component
            });
    }


}